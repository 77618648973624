import React from "react";
import {
  FooterContainer,
  FooterH2,
  FooterLink,
  FooterLink1,
  FooterWrapper,
  FooterLogo,
} from "./FooterElements";
import FImage from "../../images/xl-logo.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faEnvelope, faMapMarkerAlt, faX } from "@fortawesome/free-solid-svg-icons";
import { faInstagram, faYoutube, faFacebook, faLinkedin, faTwitter, faXTwitter } from "@fortawesome/free-brands-svg-icons";
import "./../../App.css";

const Footer = () => {
  return (
    <FooterContainer>
      <FooterWrapper>
      <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}} className="foot-main">
          <div style={{display: "flex", flexDirection: "column", marginTop: "20px", width: "33%"}} className="foot-logo">
            <FooterLogo src={FImage} alt="XpertLyte"></FooterLogo>
            <p style={{color: "white", marginBottom: "0"}} className="log-p">Elevating businesses with bespoke IT solutions designed for success in the digital age.</p>
          </div>
          <div style={{display: "flex", flexDirection: "row", gap: "50px"}} className="foot-links">
            <FooterLink href="about">About</FooterLink>
            <FooterLink href="blogs">Blogs</FooterLink>
            <FooterLink href="solution">Solution</FooterLink>
          </div>
          <div style={{display: "flex", flexDirection: "row", gap: "15px"}} className="foot-social">
              <FooterLink href="https://www.instagram.com/xpertlyte/" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faInstagram} className="social" />
              </FooterLink>
              <FooterLink href="https://www.facebook.com/profile.php?id=61556315824889" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faFacebook} className="social" />
              </FooterLink>
              <FooterLink href="https://www.linkedin.com/company/xpertlyte/" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faLinkedin} className="social" />
              </FooterLink>
              <FooterLink href="https://x.com/XpertLyte" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faXTwitter} className="social" />
              </FooterLink>
          </div>
        </div>
        <hr style={{color: "#fff"}}></hr>
        <div style={{display: "flex", justifyContent: "center", gap: "10px"}}>
          <div style={{ color: "#ccc" }} className="crpp">
          © 2024 <FooterLink1 href="/" style={{marginTop: "0", color: "#ccc", display: "inline-flex"}}>XpertLyte</FooterLink1> - All Rights Reserved
          </div>
          <div style={{borderRight: "1px solid #ccc"}}></div>
          <FooterLink1 href="privacy-policy" style={{marginTop: "0", color: "#ccc"}} className="crpp">Privacy Policy</FooterLink1>
        </div>
      </FooterWrapper>
    </FooterContainer>
  );
};

export default Footer;
