import { faEnvelope, faMapLocationDot, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styled from "styled-components";

const StickyContact = () => {
    const IconBar = styled.div`
      position: fixed;
      top: 50%;
  border-radius: 10px;
  overflow: hidden;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 100;
    `
    const A = styled.a`
      display: block;
  text-align: center;
  padding: 15px;
  transition: all 0.3s ease;
  color: black;
  font-size: 20px;
  background-color: #e0703b;
  opacity: 0.75;

  :hover {
    background-color: #000;
    color: white;
    opacity: 1;
  }
    `
    return (
        <IconBar>
            <A href = "tel: +91 8879934521"><FontAwesomeIcon icon={faPhone}></FontAwesomeIcon></A>
            <A href = "mailto: hello@xpertlyte.com"><FontAwesomeIcon icon={faEnvelope}></FontAwesomeIcon></A>
            <A href = "https://www.google.com/maps/place/Akshar+Business+Park/@19.0788356,73.0107882,17z/data=!3m1!4b1!4m6!3m5!1s0x3be7c168b615608d:0xc4724ace45d87091!8m2!3d19.0788356!4d73.0133631!16s%2Fg%2F11hzjg9_5v?entry=ttu" target="_blank"><FontAwesomeIcon icon={faMapLocationDot}></FontAwesomeIcon></A>
        </IconBar>
    );
};

export default StickyContact;