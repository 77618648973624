import styled from "styled-components";
import Image from "../../images/sol-back.jpg";

export const Section1 = styled.div`
  background-image: linear-gradient(to right, rgb(212, 237, 244,1), rgb(212, 237, 244,0.4)), url(${Image});
  background-position: right;
  background-size: cover;
  background-color: #d4edf4;
  height: 750px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  letter-spacing: 1px;
  align-items: flex-start;
  padding: 0 10px;
`

export const Section2 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
  padding: 50px 20px;
  gap: 20px;
`

export const H1 = styled.h1`
  color: #000;
  font-weight: 900;
  font-size: 50px;
  margin-top: 50px;
  margin-bottom: 40px;
  margin-left: 50px;

  @media screen and (max-width: 768px) {
    font-size: 35px;
    margin-bottom: 30px;
    margin-left: 20px;
  }

  @media screen and (max-width: 431px) {
    font-size: 30px;
    margin-bottom: 20px;
    margin-left: 0;
    text-align: center;
  }
`

export const H2 = styled.h2`
  font-weight: bold;
  margin-top: 50px;
`

export const Choose = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px 80px;

  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
    padding: 0;
    margin: auto;
    width: 90vw;
  }
`

export const Choose1 = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 768px) {
    width: 100vw;
  }
`

export const Choose2 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
`

export const ChooseWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: center;
  align-items: center;
  margin: 0 50px;
  padding-bottom: 50px;

  @media screen and (max-width: 768px) {
    gap: 20px;
  }
`

export const ChooseCard = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #d4edf4;
  padding: 20px;
  height: 375px;
  width: 23%;
  overflow: hidden;
  border-radius: 30px;

  :hover {
    transform: scale(1.05);
    box-shadow: 0 0 26px rgba(9, 9, 9, 0.3);
    box-sizing: border-box;
  }

  @media screen and (max-width: 1280px) {
    width:  40%;
    height: 275px;
  }

  @media screen and (max-width: 768px) {
    width: 80% !important;
    height: 280px;
    margin: 0;
  }

  @media screen and (max-width: 431px) {
    width: 90% !important;
    height: 300px;
  }

  @media screen and (max-width: 431px) {
    width: 100% !important;
  }
`



export const P = styled.p `
  text-align: center;
  margin-top: 10px;
`

export const Img = styled.img `
  @media screen and (max-width: 768px){
    width: 50vw !important;
    height: auto;
  }
`

export const Img1 = styled.img `
  @media screen and (max-width: 768px){
    width: 20vw !important;
    height: auto;
  }

  @media screen and (max-width: 431px){
    width: 30vw !important;
    height: auto;
  }
`

export const Li = styled.li `
  font-weight: bold;
  text-align: justify;
  font-size: 1.2em;
  margin-left: 80px;

  @media screen and (max-width: 768px) {
    font-size: 1em;
    margin-left: 40px;
  }

  @media screen and (max-width: 431px) {
    font-size: .9em;
    margin-left: 30px;
  }
`
export const H3 = styled.h3 `
  color: #1e3045;
  text-align: left;
  font-weight: bold;
  margin-left: 50px;

  @media screen and (max-width: 768px) {
    font-size: 1.5em;
    margin-left: 20px;
  }

  @media screen and (max-width: 431px) {
    font-size: 1.1em;
    margin-left: 0;
    text-align: center;
  }
`