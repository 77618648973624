import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import "../../App.css";

const SocialFeedContainer = styled.div`
  margin-top: 2rem;
`;

const SocialPost = styled.div`
  margin-bottom: 1rem;
`;

const SocialMediaFeed = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    axios.get('/api/social-media-posts').then(response => {
      setPosts(response.data);
    });
  }, []);

  return (
    <SocialFeedContainer>
    <h1 style={{textAlign: "center"}}>Social Media Feed</h1>
      {posts.map((post, index) => (
        <SocialPost key={index}>
          <p>{post.content}</p>
        </SocialPost>
      ))}
    </SocialFeedContainer>
  );
};

export default SocialMediaFeed;
