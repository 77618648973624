import styled from "styled-components";

export const CeoContainer = styled.div`
    background-color: #d4edf4;
    height: 600px;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin-top: -20px;

    @media screen and (max-width: 1355px) {
        margin-top: -70px;
    }

    @media screen and (max-width: 1200px) {
        flex-direction: column;
        height: fit-content;
        margin-top: -100px;
        margin-bottom: -20px;
    }
`

export const CeoImage = styled.div`
    flex: 1;
    text-align: left;
    margin-right: 100px;

    @media screen and (max-width: 1355px) {
        margin-right: 50px;
    }

    @media screen and (max-width: 1200px) {
        margin-right: 0;
    }

    @media screen and (max-width: 645px) {
        margin-top: -50px;
    }
`
export const Img = styled.img`
    width: auto;
    height: 600px;
    position: relative;
    top: 0;

    @media screen and (max-width: 645px) {
        width: auto;
        height: 400px;
    }
`

export const CeoMessage = styled.div`
    flex: 2;
    margin-left: 150px;
    margin-right: 50px;
    margin-top: 50px;

    @media screen and (max-width: 1355px) {
        margin-left: 50px;
        margin-right: 20px;
    }

    @media screen and (max-width: 1200px) {
        margin: auto;
        margin-top: 50px;
    }
`

export const H1 = styled.h1`
    font-weight: bold;
    color: #e0703b;
    margin: 20px 0;
`

export const P = styled.p`
    line-height: 1.5;
`