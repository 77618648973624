import React, { useState } from "react";
import { Carousel } from "react-bootstrap";
import {
  HeroContainer,
  HeroContainer1,
  HeroContainer2,
  HeroContent,
  HeroBtnWrapper,
  ArrowForward,
  ArrowRight,
} from "./HeroElements";
import { ButtonR } from "../ButtonElements";
import "../../App.css";
import styled from "styled-components";

const StyledCarousel = styled(Carousel)`
  .carousel-control-prev,
  .carousel-control-next {
    background-color: #000;
    border: none;
    font-size: 2rem;
    color: #fff;
    opacity: 0.7;
    top: 640px;
    height: 60px;
    width: 60px;
    border-radius: 50%;

    @media screen and (max-width: 431px) {
      top: 600px;
    }
  }

  .carousel-control-prev {
    left: 54px;

    @media screen and (max-width: 431px) {
      left: 38px;
    }
  }
  .carousel-control-next {
    left: 120px;

    @media screen and (max-width: 431px) {
      left: 105px;
    }
  }

  .carousel-control-prev:hover,
  .carousel-control-next:hover {
    opacity: 1;
    color: #ffffff;
  }
`;

const HeroSection = () => {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover);
  };

  return (
    <StyledCarousel controls={true} indicators={false}>
      <Carousel.Item>
        <HeroContainer>
          <HeroContent>
            <h1 className="h1-hero" style={{ color: "#000" }}>
              INNOVATE NOW
            </h1>
            <h1 className="h1-hero" style={{ color: "#E0703B" }}>
              ACCELERATE GROWTH
            </h1>
            <h1 className="h1-hero" style={{ color: "#000" }}>
              SECURE FUTURES
            </h1>
            <p className="hero-text">
              Revolutionize your Digital experience with our lightning-fast
              IT solutions tailored to elevate your business.
            </p>
            <HeroBtnWrapper>
              <ButtonR
                to="/contact"
                onMouseEnter={onHover}
                onMouseLeave={onHover}
                primary="true"
                dark="true"
              >
                Get started {hover ? <ArrowForward /> : <ArrowRight />}
              </ButtonR>
            </HeroBtnWrapper>
          </HeroContent>
        </HeroContainer>
      </Carousel.Item>
      <Carousel.Item>
        <HeroContainer1>
          <HeroContent>
            <h1 className="h1-hero" style={{ color: "#000" }}>
            IGNITE CAREER JOURNEY
            </h1>
            <h1 className="h1-hero" style={{ color: "#E0703B" }}>
            UNLEASH POTENTIAL
            </h1>
            <h1 className="h1-hero" style={{ color: "#000" }}>
            BUILD FUTURE
            </h1>
            <p className="hero-text">
            Embark on an enriching internship experience designed to ignite your passion and propel your career forward.
            </p>
            <HeroBtnWrapper>
              <ButtonR
                to="/careers"
                onMouseEnter={onHover}
                onMouseLeave={onHover}
                primary="true"
                dark="true"
              >
                Get started {hover ? <ArrowForward /> : <ArrowRight />}
              </ButtonR>
            </HeroBtnWrapper>
          </HeroContent>
        </HeroContainer1>
      </Carousel.Item>
    </StyledCarousel>
  );
};

export default HeroSection;
