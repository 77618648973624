import styled from "styled-components";

export const SigninContainer = styled.div`
    background-color: #D4EDF4;
    align-content: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    position: relative;

    @media screen and (max-width: 1025px) {
        height: fit-content;
    }

`

export const SigninWrapper = styled.div`
    margin: auto;
    align-content: center;
    justify-content: center;
    margin-top: 150px;
    display: flex;
    flex-direction: row;
    ${'' /* background-color: #F5D4C4;
    border-radius: 50px;
    padding: 50px; */};


    @media screen and (max-width: 1025px) {
        flex-direction: column;
        margin-top: 50px;
    }

`

// export const Sales = styled.div`
//     ${'' /* display: block; */}
//     position: relative;
//     ${'' /* background-color: #F5D4C4; */}
//     border-radius: 10px;
//     z-index: 1;
//     text-align: center;
// `

// export const Compliance = styled.div`
//     ${'' /* display: block; */}
//     position: relative;
//     ${'' /* background-color: #F5D4C4; */}
//     border-radius: 10px;
//     z-index: 1;
//     text-align: center;
// `

export const MapsContainer = styled.div`
    margin: 80px auto;

    @media screen and (max-width: 1200px) {
        margin: 80px 50px;
    }

    @media screen and (max-width: 1025px) {
        margin: 80px 100px;
    }

    @media screen and (max-width: 768px) {
        margin: 80px 20px;
    }
`

export const MapsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    background-color: #fff;
    gap: 20px;

    @media screen and (max-width: 1025px) {
        flex-direction: column;
    }
`

export const MapsInfo = styled.div`
    padding: 20px 40px;

    @media screen and (max-width: 768px) {
        padding: 20px;
    }
`

export const A = styled.a`
    border-radius: 50px;
    background: ${({primary}) => (primary ? '#E0703B' : '#010606')};
    white-space: nowrap;
    padding: ${({big}) => (big ? '14px 48px' : '12px 30px')};
    color: ${({dark}) => (dark ? '#010606' : '#fff')};
    font-size: ${({fontBig}) => (fontBig ? '20px' : '16px')};
    outline: none;
    border: none;
    cursor: pointer;
    display: inline-block;
    text-align: center;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease-in-out;
    text-decoration: none;
    font-weight: 600;

    &:hover {
        background: #E0703B;
        color: #fff;
    }
`

export const Iframe = styled.iframe`
    border: 0;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;

    @media screen and (max-width: 1025px) {
        border-bottom-left-radius: 0;
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
    }

    @media screen and (max-width: 768px) {
        height: 300px;
    }
`