import styled from "styled-components";

export const FooterContainer = styled.div`
    background: #545454;

`

export const FooterWrapper = styled.div`
    padding-bottom: 2rem;
    padding-left: 50px;
    padding-right: 50px;

    @media screen and (max-width: 768px){
        padding-left: 20px;
        padding-right: 20px;
    }
`

export const FooterH2 = styled.h2`
    font-size: 1.3rem;
    color: white;
    font-weight: 500;
    margin: 30px 0;

`
export const FooterLogo = styled.img`
  width: 345px;
  height: auto;

  @media screen and (max-width: 768px) {
    width: 250px;
  }
`;

export const FooterLink = styled.a`
    font-size: 0.9rem;
    text-decoration: none;
    color: #fff;
    display: flex;
    align-items: center;
    transition: all 0.3s;
    margin-top: 20px;

    &:hover {
        color: #E0703B;
    }

`

export const FooterLink1 = styled.a`
    font-size: 0.9rem;
    text-decoration: none;
    color: #fff;
    display: flex;
    align-items: center;
    transition: all 0.3s;
    margin-top: 20px;

    &:hover {
        color: #E0703B;
    }

  @media screen and (max-width: 465px) {
    font-size: 12px !important;
  }

  @media screen and (max-width: 485px) {
    font-size: 10px !important;
  }

`