import React from 'react'
import "../../App.css";
import {SidebarContainer, Icon, CloseIcon, SidebarRoute, SideBtnWrap, SidebarWrapper, SidebarMenu, SidebarLink} from './SidebarElements'

export const Sidebar = ({isOpen, toggle}) => {
    return (
        <SidebarContainer isOpen={isOpen} onClick={toggle}>
            <Icon onClick={toggle}>
                <CloseIcon />
            </Icon>
            <SidebarWrapper>
                <SidebarMenu>
                    <SidebarRoute to="/" onClick={toggle}>
                        Home
                    </SidebarRoute>
                    <SidebarRoute to="about" onClick={toggle}>
                        About
                    </SidebarRoute>
                    <SidebarRoute to="solution" onClick={toggle}>
                        Solution
                    </SidebarRoute>
                    <SidebarRoute to="blogs" onClick={toggle}>
                        Blogs
                    </SidebarRoute>
                    <SidebarRoute to="careers" onClick={toggle}>
                        Careers
                    </SidebarRoute>
                    <SidebarRoute to="contact" onClick={toggle}>
                        Contact
                    </SidebarRoute>
                </SidebarMenu>
            </SidebarWrapper>
        </SidebarContainer>
    )
}