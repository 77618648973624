import React, { useState, useEffect } from "react";
import { Navbar } from "./../components/navbar";
import { Sidebar } from "./../components/sidebar";
import Sol from "../components/solution";
import Footer from "../components/footer";
import StickyContact from "../components/sticky-contact";

export const Solution = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = 'Tailored Solutions: Precision Engineered for Success';
  }, []);

  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <Sol />
      <Footer />
      <StickyContact />
    </>
  );
};