import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../App.css";
import { InfoContainer, InfoWrapper, Title, WrapAbout, Who, What, P } from "./InfoElements";
import { ButtonBasic } from "../ButtonElements";
import WhatImage from "../../images/who-gif.gif"
import backgroundVideo from "../../images/About Us.mp4"

export const InfoSection = () => {
  const [showWho, setShowWho] = useState(true);

  useEffect(() => {
    const timer = setInterval(() => {
      setShowWho(!showWho);
    }, 15000);
    return () => clearInterval(timer);
  }, [showWho]);

  return (
    <>
      <InfoContainer>
        <InfoWrapper>
          <video autoPlay loop muted playsInline className="bg-video">
            <source src={backgroundVideo} type="video/mp4" />
          </video>
          <WrapAbout>
            {showWho ? (
              <Who>
                <Title>Who We Are?</Title>
                <P>
                  XpertLyte is a dynamic team of tech enthusiasts, developers, designers, and strategists dedicated to delivering cutting-edge solutions tailored to meet the unique needs of our clients. With years of collective experience and a commitment to excellence, we pride ourselves on our ability to turn ideas into reality and challenges into opportunities.
                </P>
              </Who>
            ) : (
              <What>
                <Title>What We Do?</Title>
                <P>
                  From web development and software development to app development, testing services, cybersecurity, and tech consulting, we offer a comprehensive suite of services designed to propel businesses forward in today’s rapidly evolving digital landscape. Whether you’re a startup looking to establish your online presence or an established enterprise seeking to optimize your operations, we have the expertise and creativity to bring your vision to life.
                </P>
              </What>
            )}
            <img src={WhatImage} style={{ width: "400px", height: "auto" }} className="ab-img"></img>
          </WrapAbout>
        </InfoWrapper>
      </InfoContainer>
    </>
  );
};

export default InfoSection;