import React, { useEffect, useState } from "react";
import HeroSection from "../components/heroSection";
import { Navbar } from "./../components/navbar";
import { Sidebar } from "./../components/sidebar";
import Services from "../components/services";
import Footer from "../components/footer";
import StickyContact from "../components/sticky-contact";

export const Home = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [someState, setSomeState] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = 'XpertLyte: Expert Solutions at Lightning Speed';
  }, []);

  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <HeroSection />
      <Services />
      <Footer />
      <StickyContact />
    </>
  );
};