import styled from "styled-components";

export const InfoContainer = styled.div `
    width: 100%;
    height: auto;
    justify-content: center;  
    display: flex;
    background: linear-gradient(to bottom, rgba(212, 237, 244, 0.7), transparent);

`



export const InfoWrapper = styled.div `
    display: flex;
    padding: 160px 150px 80px 150px;
    justify-content: center;
    align-items: center;
    background: linear-gradient(to top, rgba(212, 237, 244, 1), transparent);

    @media screen and (max-width: 1205px) {
        padding: 120px 50px 50px 50px;
    }

    @media screen and (max-width: 935px) {
        padding: 120px 20px 20px 20px;
    }

`

export const Title = styled.h1 `
    font-weight: 900;
    color: #E0703B;

`

export const P = styled.p `
    color: #020202;


`

export const WrapAbout = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 50px;
    background-color: rgba(255, 255, 255, 0.9);
    padding: 40px 50px;
    border-radius: 30px;

    @media screen and (max-width: 612px) {
        flex-direction: column-reverse;
        gap: 10px;
    }

    @media screen and (max-width: 500px) {
        padding: 20px 30px !important;
    }

`

export const Who = styled.div`
    width: 100%;

`
export const What = styled.div`
    width: 100%;

`