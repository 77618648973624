import React, { useEffect, useState } from "react";
import { Navbar } from "./../components/navbar";
import { Sidebar } from "./../components/sidebar";
import Footer from "../components/footer";
import PP from "../components/privacy-policy";
import StickyContact from "../components/sticky-contact";

export const PrivacyPolicy = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = 'Career Opportunities: Drive Innovation with Us';
  }, []);

    return (
        <>
        <Sidebar isOpen={isOpen} toggle={toggle} />
        <Navbar toggle={toggle} />
        <PP />
        <Footer />
        <StickyContact />
    </>
    );
};