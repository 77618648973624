import React from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
import "../../App.css";

const SliderContainer = styled.div`
  width: 100%;
  .slick-track {
    width: 100%!important;
  }
  .slick-slide img {
    width: 100%;
  }
  margin-top: 100px;
`;

const ArrowButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 24px;
  color: #333;
  transition: color 0.2s ease;

  &:hover {
    color: #666;
  }
`;

const NextArrow = ({ onClick }) => (
  <ArrowButton onClick={onClick} style={{ position: 'absolute', right: '0', top: '40%', fontSize: '50px' }}>›</ArrowButton>
);

const PrevArrow = ({ onClick }) => (
  <ArrowButton onClick={onClick} style={{ position: 'absolute', left: '0', top: '40%', fontSize: '50px' }}>‹</ArrowButton>
);

const BlogSlider = ({ posts }) => {
  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    pauseOnFocus: true,
  };

  return (
    <SliderContainer>
    <h1 style={{textAlign: "center"}}>Latest Blog Posts</h1>
      <Slider {...settings}>
        {posts.map((post, index) => (
          <div key={index}>
            <img src={post.image} alt={post.title} />
            <h3>{post.title}</h3>
          </div>
        ))}
      </Slider>
    </SliderContainer>
  );
};

const posts = [
  {
    image: 'https://example.com/image1.jpg',
    title: 'Post 1',
    description: 'This is the description of post 1',
  },
  {
    image: 'https://example.com/image2.jpg',
    title: 'Post 2',
    description: 'This is the description of post 2',
  },
  {
    image: 'https://example.com/image3.jpg',
    title: 'Post 3',
    description: 'This is the description of post 3',
  },
];

export default BlogSlider;
