import React, { useEffect, useState } from "react";
import InfoSection from "../components/infoSection";
import { Navbar } from "./../components/navbar";
import { Sidebar } from "./../components/sidebar";
import Image1 from "../images/design-notes.svg";
import Image2 from "../images/space.svg";
import InfoSectionLight from "../components/infoSectionLight";
import Footer from "../components/footer";
import ClientSection from "../components/clients";
import CeoSection from "../components/ceo";
import StickyContact from "../components/sticky-contact";

export const About = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = 'Our Story: Crafting Expert Solutions with Precision';
  }, []);

  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <InfoSection/>
      <InfoSectionLight/>
      <CeoSection />
      <ClientSection />
      <StickyContact />
      <Footer />
    </>
  );
};