import styled from "styled-components";
import {MdKeyboardArrowRight, MdArrowForward} from "react-icons/md"
import Image from "../../images/bg-copy.png";
import Image1 from "../../images/intern-home.jpeg";

export const HeroContainer = styled.div`
    background-image: linear-gradient(120deg, rgba(212,237,244,1)30%, transparent 100%), url(${Image});
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    padding: 0 30px;
    height: 750px;
    position: relative;
    z-index: 1;

    :before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 100%),
        linear-gradient(180deg, rgba(0,0,0,0.1) 0%, transparent 100%);
        z-index: 2;
    }

`
export const HeroContainer1 = styled.div`
    background-image: linear-gradient(120deg, rgba(212,237,244,1)30%, transparent 100%), url(${Image1});
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    padding: 0 30px;
    height: 750px;
    position: relative;
    z-index: 1;

    :before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 100%),
        linear-gradient(180deg, rgba(0,0,0,0.1) 0%, transparent 100%);
        z-index: 2;
    }

`

export const HeroContent = styled.div`
    z-index: 3;
    max-width: 1200px;
    position: absolute;
    padding: 8px 24px;
    display: flex;
    flex-direction: column;
    align-items: start;

    @media screen and (max-width: 431px){
        padding: 6px 10px;
    }

`

export const HeroBtnWrapper = styled.div`
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    align-items: start;

`

export const ArrowForward = styled(MdArrowForward)`
    margin-left: 8px;
    font-size: 20px;

`

export const ArrowRight = styled(MdKeyboardArrowRight)`
    margin-left: 8px;
    font-size: 20px;

`
