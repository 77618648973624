import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../App.css"
import {CeoContainer, CeoImage, CeoMessage, Img, H1, P, BackDesign} from "./ceoElements";
import Ceo from "../../images/ceo.png"

export const CeoSection = () => {
    return (
        <CeoContainer>
        <CeoMessage>
            <H1>CEO Message</H1>
            <P>Dear Valued Partners, Clients, and Team Members,<br></br><br></br>
            Welcome to XpertLyte. As the CEO, I am proud to lead our dedicated team in delivering cutting-edge IT solutions that empower businesses to thrive in a digital world. Our commitment to innovation, integrity, and customer-centricity ensures we provide reliable and scalable technology services tailored to our clients' unique needs. With substantial investments in research and development, our solutions—from AI and cybersecurity to cloud computing and blockchain—are always at the forefront of technology. Before joining XpertLyte, I held senior positions at leading tech firms, driving transformative projects and setting industry standards. Thank you for your continued trust and partnership. Together, we are shaping the future of technology.</P>
        </CeoMessage>
        <CeoImage>
        <div class="blue-design">
        <Img src={Ceo} alt="CEO"></Img>
            <div class="blue-shape shape4"></div>
            <div class="blue-shape shape1"></div>
            <div class="blue-shape shape2"></div>
            <div class="blue-shape shape3"></div>
        </div>
        </CeoImage>
        </CeoContainer>
    );
};

export default CeoSection;